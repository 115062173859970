import React from 'react';

const IPhoneMockup = () => (
  <div className="text-center p-5" style={{ paddingTop: '40px', paddingBottom: '40px' }}> {/* Adding padding top here */}
    <img src="/cleo-mockup-1.png" alt="iPhone Mockup" className="mx-auto" />
  </div>
);

export default IPhoneMockup;

