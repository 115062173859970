import React from 'react';
import './App.css'; // Make sure to import the CSS file for styling
import Header from './components/Header';
import MissionStatement from './components/MissionStatement';
import IPhoneMockup from './components/IPhoneMockup';
import EmailForm from './components/EmailForm';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <MissionStatement />
      <IPhoneMockup />
      <EmailForm />
      <Footer />
    </div>
  );
}

export default App;