import React from 'react';
import './EmailForm.css'; // Importing the CSS for styling

const EmailForm = () => {
  return (
    <div className="email-form-container">
      <form className="email-form">
        <input
          type="email"
          placeholder="Email address"
          className="email-input"
        />
        <button type="submit" className="submit-btn">
          get early access
        </button>
      </form>
    </div>
  );
};

export default EmailForm;

