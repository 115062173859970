import React from 'react';

const Header = () => (
  <header className="text-center p-5" style={{ paddingTop: '40px', paddingBottom: '10px' }}>
    {/* Place your logo image here */}
    <img src="/cleo.png" alt="App Logo" className="mx-auto" />
  </header>
);

export default Header;
